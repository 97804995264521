/* You can add global styles to this file, and also import other style files */
@import "../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css";
@import '../node_modules/material-icons/iconfont/material-icons.css';


body {
  background-color: #95BDD6;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  margin: 0%;
}

html,
body {
  height: 100%;
}

h2 {
  color: white;
  margin-left: 5%;
  font-size: 1.4em;
  font-weight: bolder;
}

.mat-mdc-checkbox label {
  color: white;
}

/* focus material input field color */
.mdc-text-field--filled .mdc-line-ripple::after {
  border-bottom-color: #95BDD6;
}

.mat-sort-header-arrow {
  opacity: 1 !important;
  color: #6b8294;
  background-color: white;
  border: solid 3px white;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #95BDD6;
}

.mat-mdc-paginator {
  background: #B9C8D2;
  color: #6b8294;
  border: solid 2px;
}

.mat-mdc-select-value {
  color: #6b8294;
}

.mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #6b8294;
  --mat-mdc-button-ripple-color: #6b8294;
}

.mat-mdc-paginator .mdc-line-ripple::after {
  border-bottom-color: #95BDD6;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #95BDD6;
}

.mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #95BDD6);
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: white;
  border: 2px solid #6b8294;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
  color: #6b8294;
}

/* paginator style */
.mdc-list-item--selected .mdc-list-item__primary-text,
.mdc-list-item--activated .mdc-list-item__primary-text {
  color: #95BDD6;
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: #6b8294;
}

button.custom-date-class {
  background-color: #95BDD6;
  border-radius: 100%;
}

.mdc-list-item__primary-text,
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: #6b8294;
}

.mat-mdc-radio-button .mdc-form-field {
  color: white;
}

.mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: white;
  --mdc-radio-disabled-unselected-icon-color: white;
  --mdc-radio-unselected-focus-icon-color: white;
  --mdc-radio-unselected-hover-icon-color: white;
  --mdc-radio-unselected-icon-color: white;
  --mdc-radio-unselected-pressed-icon-color: white;
  --mdc-radio-selected-focus-icon-color: #6b8294;
  --mdc-radio-selected-hover-icon-color: #6b8294;
  --mdc-radio-selected-icon-color: #6b8294;
  --mdc-radio-selected-pressed-icon-color: #6b8294;
  --mat-mdc-radio-ripple-color: white;
  --mat-mdc-radio-checked-ripple-color: #6b8294;
}

.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) .mdc-evolution-chip__text-label {
  color: #6b8294;
}